import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";

import withDynamic from "../../containers/withDynamic";
import * as DynamicContents from "./Contents";

function DynamicContent(props) {
	const component = get(props, "component", "");

	return (
		React.createElement(DynamicContents[component], { data: get(props, get(props, "component", ""), {}) })
	);
}

export default withDynamic(DynamicContent);

DynamicContent.propTypes = {
	DynamicNews: PropTypes.object,
	DynamicArticles: PropTypes.object,
	DynamicTextPages: PropTypes.object,
	slug: PropTypes.string,
	component: PropTypes.string,
};

DynamicContent.propTypes = {
	DynamicNews: {},
	DynamicArticles: {},
	DynamicTextPages: {},
	slug: "",
	component: "",
};
