import React from "react";
import { graphql } from "@apollo/react-hoc";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";

const DYNAMIC_NEWS = gql`
    query FetchData($slug: String!) {
        news(where: { slug: { _eq: $slug } }) {
            slug
			published_at
			issued_at
			main_image
			main_image_preview
			page_title_full
			page_title_short
			seo
			teaser
			content_blocks
			category {
				slug
				title_full
			}
			author {
				name
			}
			age_rating
        }
    }
`;

const DYNAMIC_ARTICLES = gql`
	query FetchData($slug: String!) {
		media_articles(where: { slug: { _eq: $slug } }) {
			title_full
			page_title_full
			page_title_short
			slug
			teaser
			main_image
			issued_at
			seo
			content_blocks
			author {
				name
			}
			age_rating
		}
	}
`;

const DYNAMIC_PAGES = gql`
    query FetchData($slug: String!) {
        text_pages(where: { slug: { _eq: $slug } }) {
            slug
            page_title_full
            main_image
            content_blocks
            seo
        }
    }
`;

export default function withDynamic(Component) {
	class DynamicComponent extends React.Component {
		render() {
			return <Component {...this.props} />;
		}
	}

	DynamicComponent.displayName = `News(${Component.displayName || Component.name || "Component"})`;

	return compose(
		graphql(DYNAMIC_NEWS, {
			options: props => ({
				fetchPolicy: "no-cache",
				variables: {
					slug: props.slug,
				},
			}),
			name: "DynamicNews",
		}),
		graphql(DYNAMIC_ARTICLES, {
			options: props => ({
				fetchPolicy: "no-cache",
				variables: {
					slug: props.slug,
				},
			}),
			name: "DynamicArticles",
		}),
		graphql(DYNAMIC_PAGES, {
			options: props => ({
				fetchPolicy: "no-cache",
				variables: {
					slug: props.slug,
				},
			}),
			name: "DynamicTextPages",
		}),
	)(DynamicComponent);
}
