import React, { useEffect } from "react";
import { parse } from "query-string";
import PropTypes from "prop-types";
import { get, compact, first, last } from "lodash";

import EmptyData from "../../components/EmptyData";
import DynamicContent from "../../components/DynamicContent";
import { removePreloader } from "../../helpers";

const RedirectedPage = ({ location }) => {
	const page_types = {
		news: "DynamicNews",
		articles: "DynamicArticles",
		pages: "DynamicTextPages",
	};

	const uri_list = compact(get(parse(get(location, "search", "")), "uri", "").split("/"));

	const component = page_types[first(uri_list)];

	if (uri_list && !!uri_list.length) {
		return <DynamicContent slug={last(uri_list)} component={component} />;
	}

	useEffect(() => {
		removePreloader();
	}, []);

	return <EmptyData />;
};

export default RedirectedPage;

RedirectedPage.propTypes = {
	location: PropTypes.object,
};

RedirectedPage.defaultProps = {
	location: {},
};
