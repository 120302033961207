import React from "react";
import { get, compact } from "lodash";
import PropTypes from "prop-types";

import { ContentBlock, TextBlockMedia } from "../../Blocks";
import Pages from "../../Pages";
import { translit, markdownConverter, removeEmptyKeys } from "../../../helpers";
import Breadcrumbs from "../../Layout/Breadcrumbs";
import { useThemeContext } from "../../Layout/ThemeContext";
import { MetaMainEntity, MetaDescription, MetaImage, MetaDatePublished } from "../../Meta";
import EmptyData from "../../EmptyData";

const moment = require("moment");

export function DynamicNews({ data }) {
	const { theme } = useThemeContext();

	const news = get(data, "news[0]", {});
	const { content_blocks, slug, page_title_full, teaser, issued_at, author, age_rating = "", settings } = news;
	const categoryTitleFull = get(news, "category.title_full", "");

	return news ? (
		<Pages seo={news}>
			<ContentBlock>
				<div className="container">
					<Breadcrumbs
						currentLabel={compact([categoryTitleFull, page_title_full]).join(" / ")}
					/>
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"} mount={content_blocks && !!content_blocks.length}>
				<div className={"container"} itemScope itemType="https://schema.org/NewsArticle">
					<MetaMainEntity
						content={compact([
							"news",
							categoryTitleFull,
							slug,
						]).join("/")}
					/>
					<MetaDescription content={teaser} />
					<div className={"h1 news_title"}>
						<h1 itemProp="headline">{page_title_full}</h1>
					</div>
					<div className={"d-flex justify-content-between text-block__subtitle"}>
						<div
							className={"text-block__subtitle_category"}
							itemProp="articleSection"
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.text,
							})}
						>
							{categoryTitleFull}
							{age_rating && <span className={"age_rating m-3"}>{age_rating}</span>}
						</div>
						<div
							className={"text-block__subtitle_date"}
							itemProp="datePublished"
							content={moment(issued_at).format("YYYY-MM-DD")}
						>
							{!get(settings, "hide_published_at", false) ? moment(issued_at).format("L") : ""}
						</div>
					</div>
					<MetaImage content={get(news, "main_image.src", "")} />
					{content_blocks &&
						content_blocks.map(({ title, body, media, type }, idx) => {
							return (
								<TextBlockMedia
									key={`${translit(title)}-${idx}`}
									title={title}
									items={media ? media.items : []}
									desc={markdownConverter(body)}
									left={idx % 2 !== 0}
									orientation={type}
								/>
							);
						})}
					{author && (
						<div
							className={"mb-5"}
							style={Object.assign({}, {
								textAlign: "right",
								fontStyle: "italic",
							}, removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h4,
								lineHeight: theme.lineHeight.h4,
								color: theme.color.text,
							}))}
							itemProp="author"
						>
							{author.name}
						</div>
					)}
				</div>
			</ContentBlock>
		</Pages>
	) : (
		<EmptyData />
	);
}

DynamicNews.propTypes = {
	data: PropTypes.object,
};

export function DynamicArticles({ data }) {
	const { theme } = useThemeContext();

	const articles = get(data, "media_articles[0]", {});
	const { slug, title_full, content_blocks, page_title_full, issued_at, teaser, author, age_rating = "" } = articles;

	return articles ? (
		<Pages seo={articles}>
			<ContentBlock>
				<div className="container">
					<Breadcrumbs currentLabel={`Cтатьи / ${title_full}`} />
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"} mount={content_blocks && !!content_blocks.length}>
				<div className={"container"} itemScope itemType="https://schema.org/Article">
					<MetaMainEntity content={`/articles/${slug}`} />
					<MetaDescription content={teaser} />
					<MetaDatePublished content={moment(issued_at).format("YYYY-MM-DD")} />
					<div className={"h1 article_title"}>
						<h1 itemProp="headline">{page_title_full}</h1>
					</div>
					<div
						className={"text-block__subtitle_category"}
						itemProp="articleSection"
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						{get(articles, "category.title_full", "")}
						{age_rating && <span className={"age_rating m-3"}>{age_rating}</span>}
					</div>
					<MetaImage content={get(articles, "main_image.src", "")} />
					{content_blocks &&
						content_blocks.map(({ title, body, media, type }, idx) => {
							return (
								<TextBlockMedia
									key={`${translit(title)}-${idx}`}
									title={title}
									items={media ? media.items : []}
									desc={markdownConverter(body)}
									left={idx % 2 !== 0}
									orientation={type}
								/>
							);
						})}
					{author && (
						<div className={"mb-5"}
							style={Object.assign({}, {
								textAlign: "right",
								fontStyle: "italic",
							}, removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h4,
								lineHeight: theme.lineHeight.h4,
								color: theme.color.text,
							}))}
							itemProp="author"
						>
							{get(author, "name", "")}
						</div>
					)}
				</div>
			</ContentBlock>
		</Pages>
	) : (
		<EmptyData />
	);
}

DynamicArticles.propTypes = {
	data: PropTypes.object,
};

export function DynamicTextPages({ data }) {
	const textPages = get(data, "text_pages[0]", {});
	const { page_title_full, content_blocks } = textPages;

	return textPages ? (
		<Pages seo={textPages}>
			<ContentBlock>
				<div className="container">
					<Breadcrumbs currentLabel={page_title_full} />
				</div>
			</ContentBlock>
			<ContentBlock
				key={"media-content"}
				mount={content_blocks && !!content_blocks.length}
				className={"text-page"}
			>
				<div className={"container"}>
					<div className={"h1 text-page_title"}>
						<h1 itemProp="headline">{page_title_full}</h1>
					</div>
					{content_blocks &&
						content_blocks.map(({ title, body, media, type }, idx) => {
							return (
								<TextBlockMedia
									key={`${translit(title)}-${idx}`}
									title={title}
									items={media ? media.items : []}
									desc={markdownConverter(body)}
									left={idx % 2 !== 0}
									orientation={type}
								/>
							);
						})}
				</div>
			</ContentBlock>
		</Pages>
	) : (
		<EmptyData />
	);
}

DynamicTextPages.propTypes = {
	data: PropTypes.object,
};
